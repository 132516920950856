import React from 'react';



// Import all components
const SaasOnepage = React.lazy(() => import('./pages/Saas Onepage/index'));

const routes = [
    { path: '/', component: SaasOnepage }    
];

export default routes;